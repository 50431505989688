import React, { Component } from 'react';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
class App extends Component {
  render() {
    return (
      <div className="content-section implementation">

        </div>
    )
  }
}

export default App;
